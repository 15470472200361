import React from 'react';


const Header = () => {
    return (
        <header className="site-header">
            <div className="site-name">
                Arquitectura de Software
            </div>
        </header>
    );
};

export default Header;
